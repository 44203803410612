import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';

import { 
  Box, 
  CircularProgress, 
  Table, 
  TableBody, 
  TableCell, 
  TableFooter, 
  TableHead, 
  TablePagination, 
  TableRow, 
  TableSortLabel, 
  Typography 
} from '@mui/material';

import useTablePaginationInit from '../../../../../components/hooks/useTablePaginationInit';
import TablePaginationActions from '../../../../../components/utils/TablePaginationActions';
import TableTimeStamps from '../../../../../components/utils/TableTimeStamps';
import Copyable from '../../../../../components/utils/Copyable';
import TopBar from '../../../../../components/utils/TopBar';
import NA from '../../../../../components/utils/NA';

import { 
  GetPartiesBankAccountsBIZfusionALT 
} from '../../../../../redux/actions/business/fusion/altcard/partiesBankAccounts';

const PartiesBankAccountsPage = () => {
  const dispatch = useDispatch();
  const state = useSelector(state => state["business.altcard"]);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState(null);
  const [sortDirection, setSortDirection] = useState("asc");

  const [partiesBankAccountsList, setPartiesBankAccountsList] = useState([]);
  const [partiesBankAccountsTotal, setPartiesBankAccountsTotal] = useState(0);

  const [
    page, 
    rowsPerPage, 
    handleChangePage, 
    handleChangeRowsPerPage
  ] = useTablePaginationInit();

  const tableHeadingsList = [
    {
      label: "Id",
      sort: "id",
    },
    // {
    //   label: "Legal name",
    //   sort: "legal_name",
    // },
    // {
    //   label: "Logo url",
    //   sort: "logo_url",
    // },
    // {
    //   label: "Color",
    //   sort: "color",
    // },
    // {
    //   label: "Tax id type",
    //   sort: "tax_id_type",
    // },
    // {
    //   label: "Tax id",
    //   sort: "tax_id",
    // },
    // {
    //   label: "Incorporation date",
    //   sort: "incorporation_date",
    // },
    // {
    //   label: "Biz handle",
    //   sort: "business_handle",
    // },
    {
      label: "Time stamps",
      sort: "createdAt",
    },
    // {
    //   label: "",
    //   sort: null
    // }
  ];

  const handleSort = (value) => {
    setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    setSort(value);
  };

  useEffect(() => {
    handleGetPartiesBankAccounts();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage, search, sort, sortDirection]);

  useEffect(() => {
    try {
      formatPartiesBankAccountsList(state.partiesBankAccountsList.records || []);
      setPartiesBankAccountsTotal(state.partiesBankAccountsList.total || 0);
    } catch (err) {}
  }, [state]);

  const formatPartiesBankAccountsList = (list) => {
    setPartiesBankAccountsList(list)
  };

  async function handleGetPartiesBankAccounts(customLoading){
    let query = ""
    if(search) query += `&search=${search}`;
    if(sort) query += `&field=${sort}`;
    if(sortDirection) query += `&order=${sortDirection}`;
    dispatch(GetPartiesBankAccountsBIZfusionALT(page+1, rowsPerPage, query, customLoading ? customLoading : setLoading));
  };

  return (
    <Box>

      <TopBar
        title={"Parties bank accounts"}
        search={search}
        onSearchChange={(e) => {
          setSearch(e.target.value);
        }}
        />

      {
        loading
        ?
        <Box height={"300px"} className="flexCenterCenterRow">
          <CircularProgress />
        </Box>
        :
          partiesBankAccountsList.length === 0
          ?
          <Box height={"300px"} className="flexCenterCenterRow">
            <Typography>No party bank account found</Typography>
          </Box>
          :
          <Table className="block">
            <TableHead>
              <TableRow>
                {
                  tableHeadingsList.map((heading, headingIndex) => (
                    heading.sort === null
                    ?
                    <TableCell
                      key={headingIndex}>{heading.label}</TableCell>
                    :
                    <TableCell
                      key={headingIndex}>
                      <TableSortLabel
                        active={sort === heading.sort ? true : false}
                        direction={sortDirection}
                        onClick={() => {
                          handleSort(heading.sort);
                        }}
                      >
                        {heading.label}
                      </TableSortLabel>
                    </TableCell>
                  ))
                }
              </TableRow>
            </TableHead>
            <TableBody>
              {
                partiesBankAccountsList.map((row, index) => (
                  <TableRow 
                    key={index}>
                    <TableCell>
                      <Typography
                        variant='caption'
                        color={"grey"}>
                        <Copyable>
                          {row.id || <NA />}
                        </Copyable>
                      </Typography>
                    </TableCell>
                    {/* <TableCell>
                      <Copyable>
                        {row.legal_name || <NA />}
                      </Copyable>
                    </TableCell>
                    <TableCell>
                      <Copyable>
                        {row.logo_url || <NA />}
                      </Copyable>
                    </TableCell>
                    <TableCell>
                      <Copyable>
                        {row.color || <NA />}
                      </Copyable>
                    </TableCell>
                    <TableCell>{row.tax_id_type || <NA />}</TableCell>
                    <TableCell>
                      <Copyable>
                        {row.tax_id || <NA />}
                      </Copyable>
                    </TableCell>
                    <TableCell>
                      <Copyable>
                        {row.incorporation_date || <NA />}
                      </Copyable>
                    </TableCell>
                    <TableCell>
                      <Copyable>
                        {row.business_handle || <NA />}
                      </Copyable>
                    </TableCell> */}
                    <TableCell>
                      <TableTimeStamps
                        createdAt={row.createdAt}
                        updatedAt={row.updatedAt}
                        />
                    </TableCell>
                    {/* <TableCell>
                      <IconButton
                        onClick={(e) => {
                          setCurrenciesMenuAnchor(e.currentTarget);
                          setCurrenciesMenuOpen(true);
                          setUpdate(row);
                          setModalTitle(row.name || "");
                        }}>
                        <MoreVertIcon sx={{ color: global['secondary_light']}}/>
                      </IconButton>
                    </TableCell> */}
                  </TableRow>
                ))
              }
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  sx={{ borderBottom: 0 }}
                  rowsPerPageOptions={[5, 10, 25, 50, 100]} 
                  colSpan={10}
                  count={partiesBankAccountsTotal}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: {
                      'aria-label': 'rows per page'
                    },
                    native: true
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                  />
              </TableRow>
            </TableFooter>
          </Table>
      }
    </Box>
  )
}

export default PartiesBankAccountsPage;