import React from 'react';

import { 
  Box,
  Typography
} from '@mui/material';

import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import "../styles.scss";

const FusionAltcard = ({
  navigate,
  currentPage
}) => {
  return (
    <Box className="nav__side-common" 
      display={"flex"} flexDirectsion={"column"} gap={"10px"}>
      <Box className="nav__side-container">
        <Box className={`nav__side-item flexCenter_Row`}
          onClick={() => {
            navigate("/business/fusion");
          }}>
          <ArrowBackIcon />
          <Typography variant='body2'>
            Back
          </Typography>
        </Box>
        <Box className={`${currentPage === 0 ? 'nav__side-selected' : 'nav__side-item'} flexCenter_Row`}
          onClick={() => {
            navigate("/business/fusion/altcard");
          }}>
          <HomeOutlinedIcon />
          <Typography variant='body2'>
            Home Page
          </Typography>
        </Box>
        <Box className={`${currentPage === 1 ? 'nav__side-selected' : 'nav__side-item'} flexCenter_Row`}
          onClick={() => {
            navigate("/business/fusion/altcard/parties");
          }}>
          <BusinessOutlinedIcon />
          <Typography variant='body2'>
            Parties
          </Typography>
        </Box>
				<Box className={`${currentPage === 2 ? 'nav__side-selected' : 'nav__side-item'} flexCenter_Row`}
          onClick={() => {
            navigate("/business/fusion/altcard/parties-bank-accounts");
          }}>
          <AccountBalanceOutlinedIcon />
          <Typography variant='body2'>
            Parties bank accounts
          </Typography>
        </Box>
      </Box>
    </Box>  
  )
}

export default FusionAltcard;