import React, { useEffect, useState } from 'react';
import axios from 'axios';

import { 
  Box,  
  MenuItem, 
  Skeleton, 
  TextField, 
  Typography 
} from '@mui/material';

import TopBar from '../../../components/utils/TopBar';
import NA from '../../../components/utils/NA';

import "./styles.scss";

const EnvironmentsList = [
  { label: "ALPHA", value: "alpha" },
  { label: "PROD", value: "prod" }
];

const AppVersionsPage = () => {
  const [selectedEnvironment, setSelectedEnvironment] = useState();
  const [loadingFE, setLoadingFE] = useState(Array(4).fill(true));
  const [loadingBE, setLoadingBE] = useState(true);

  const [appVersionsBE, setAppVersionsBE] = useState(Array(4).fill(""));

  useEffect(() => {
    setLoadingFE(Array(4).fill(true));
    handleGetVersionBE();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedEnvironment]); 
  

  let alphaBackendVersionUrls = [
    "https://alpha-api.lusid.ai/us/core/app-info",
    "https://alpha-api.lusid.ai/us/identity/app-version",
    "https://alpha-api.lusid.ai/us/fusion/app-version",
    "https://alpha-webhook.lusid.ai/app-version"
  ];

  let prodBackendVersionUrls = [
    "https://api.lusidpay.com/us/core/app-info",
    "https://api.lusidpay.com/us/identity/app-version",
    "https://api.lusidpay.com/us/fusion/app-version",
    "https://webhook.lusidpay.com/app-version"
  ]

  async function handleGetVersionBE(){
    const results = [];
    setLoadingBE(true);
    setAppVersionsBE(Array(4).fill(""));
    try {
      let urls = [];
      if(selectedEnvironment === "alpha"){
        urls = alphaBackendVersionUrls;
      } else if (selectedEnvironment === "prod"){
        urls = prodBackendVersionUrls;
      } else {};
      for (const url of urls) {
        const response = await axios.get(url);
        results.push(response.data);
      }
    } catch (error) {
      results.push("Error while fetching verison")
    } finally {
      setAppVersionsBE(results);
      setLoadingBE(false);
    }
  }

  const handleChangeFEloading = (index) => {
    let temp = loadingFE;
    temp[index] = false;
    setLoadingFE(temp);
  };

  return (
    <Box width={"100%"}>

      <TopBar 
        title={"App Versions"}
        />

      <Box
        className="flexCenter_Row"
        gap={"20px"}
        mb={"15px"}
        sx={{ width: "350px" }}>
        <Typography>
          Environment
        </Typography>
        <TextField
          select
          size='small'
          label="Environment"
          fullWidth
          value={selectedEnvironment || ""}
          onChange={(e) => {
            setSelectedEnvironment(e.target.value);
          }}
          inputProps={{ maxLength: 32 }}
          >
          {
            EnvironmentsList.map((item, index) => (
              <MenuItem
                key={index}
                value={item.value}>
                {item.label || <NA />}
              </MenuItem>
            ))
          }
        </TextField>
      </Box>

      <Box
        sx={{ 
          height: "calc(100vh - 200px)",
        }}>
        <Box  
          height={"50%"}>
          <Typography
            mb={"20px"}
            variant='subtitle2'>
            Frontend
          </Typography>
          <Box
            className="flexCenterSBRow"
            gap={"20px"}>
            <Box
              className="flexCenterCenterColumn app_card"
              gap={"10px"}>
              <Typography>
                fe-core-web
              </Typography>
              <Skeleton 
                variant="rectangular"
                sx={{
                  display: loadingFE[0] ? 'flex' : 'none',
                  width: "300px",
                  height: '100px',  
                  borderRadius: "5px",
                  backgroundColor: "#a1a1a1",
                }}
                />
              <iframe
                src={`${selectedEnvironment === "alpha" ? 'https://alpha-app.lusid.ai' : 'https://app.lusidpay.com'}/version`} 
                title="fe-core-web"
                onLoad={() => handleChangeFEloading(0)}
                style={{
                  display: loadingFE[0] ? 'none' : 'block',
                  height: '100px',  
                  border: 'none',   
                  backgroundColor: "white",
                  borderRadius: "5px",
                }}
              />
            </Box>
            <Box
              className="flexCenterCenterColumn app_card"
              gap={"10px"}>
              <Typography>
                fe-lusid-web
              </Typography>
              <Skeleton 
                variant="rectangular"
                sx={{
                  display: loadingFE[1] ? 'flex' : 'none',
                  width: "300px",
                  height: '100px',  
                  borderRadius: "5px",
                  backgroundColor: "#a1a1a1",
                }}
                />
              <iframe
                src={`${selectedEnvironment === "alpha" ? 'https://alpha.lusid.ai' : 'https://lusidpay.com'}/version`} 
                title="fe-lusid-web"
                onLoad={() =>  handleChangeFEloading(1)}
                style={{ 
                  display: loadingFE[1] ? 'none' : 'block',
                  height: '100px',  
                  border: 'none',   
                  backgroundColor: "white",
                  borderRadius: "5px"
                }}
              />
            </Box>
            <Box
              className="flexCenterCenterColumn app_card"
              gap={"10px"}>
              <Typography>
                fe-internal
              </Typography>
              <Skeleton 
                variant="rectangular"
                sx={{
                  display: loadingFE[2] ? 'flex' : 'none',
                  width: "300px",
                  height: '100px',  
                  borderRadius: "5px",
                  backgroundColor: "#a1a1a1",
                }}
                />
              <iframe
                src={`${selectedEnvironment === "alpha" ? 'https://alpha-internal.lusid.ai' : 'https://internal.lusidpay.com'}/version`} 
                title="fe-internal"
                onLoad={() => handleChangeFEloading(2)}
                style={{ 
                  display: loadingFE[2] ? 'none' : 'block',
                  height: '100px',  
                  border: 'none',   
                  backgroundColor: "white",
                  borderRadius: "5px"
                }}
              />
            </Box>
            <Box
              className="flexCenterCenterColumn app_card"
              gap={"10px"}>
              <Typography>
                fe-developer
              </Typography>
              <Skeleton 
                variant="rectangular"
                sx={{
                  display: loadingFE[3] ? 'flex' : 'none',
                  width: "300px",
                  height: '100px',  
                  borderRadius: "5px",
                  backgroundColor: "#a1a1a1",
                }}
                />
              <iframe
                src={`${selectedEnvironment === "alpha" ? 'https://alpha-internal.lusid.ai' : 'https://internal.lusidpay.com'}/version`} 
                title="fe-developer"
                onLoad={() => handleChangeFEloading(3)}
                style={{ 
                  display: loadingFE[3] ? 'none' : 'block',
                  height: '100px',  
                  border: 'none',   
                  backgroundColor: "white",
                  borderRadius: "5px"
                }}
              />
            </Box>
          </Box>
        </Box>

        <Box
          height={"50%"}>
          <Typography
            mb={"20px"}
            variant='subtitle2'>
            Backend
          </Typography>
          <Box
            className="flexCenterSBRow"
            gap={"20px"}>
            <Box
              className="flexCenterCenterColumn app_card"
              gap={"10px"}>
              <Typography>
                be-core
              </Typography>
              {
                loadingBE
                ?
                <Skeleton 
                  variant="rectangular"
                  sx={{
                    width: "300px",
                    height: '100px',  
                    borderRadius: "5px",
                    backgroundColor: "#a1a1a1",
                  }}
                  />
                :
                <Typography
                  sx={{
                    width: "300px",
                    height: '100px',  
                    color: "black",
                    backgroundColor: "white",
                    borderRadius: "5px"
                  }}>
                  {appVersionsBE[0]}
                </Typography>
              }
            </Box>
            <Box
              className="flexCenterCenterColumn app_card"
              gap={"10px"}>
              <Typography>
                be-identity
              </Typography>
              {
                loadingBE
                ?
                <Skeleton 
                  variant="rectangular"
                  sx={{
                    width: "300px",
                    height: '100px',  
                    borderRadius: "5px",
                    backgroundColor: "#a1a1a1",
                  }}
                  />
                :
                <Typography
                  sx={{
                    width: "300px",
                    height: '100px',  
                    color: "black",
                    backgroundColor: "white",
                    borderRadius: "5px"
                  }}>
                  {appVersionsBE[1]}
                </Typography>
              }
            </Box>
            <Box
              className="flexCenterCenterColumn app_card"
              gap={"10px"}>
              <Typography>
                be-fusion
              </Typography>
              {
                loadingBE
                ?
                <Skeleton 
                  variant="rectangular"
                  sx={{
                    width: "300px",
                    height: '100px',  
                    borderRadius: "5px",
                    backgroundColor: "#a1a1a1",
                  }}
                  />
                :
                <Typography
                  sx={{
                    width: "300px",
                    height: '100px',  
                    color: "black",
                    backgroundColor: "white",
                    borderRadius: "5px"
                  }}>
                  {appVersionsBE[2]}
                </Typography>
              }
            </Box>
            <Box
              className="flexCenterCenterColumn app_card"
              gap={"10px"}>
              <Typography>
                be-webhook
              </Typography>
              {
                loadingBE
                ?
                <Skeleton 
                  variant="rectangular"
                  sx={{
                    width: "300px",
                    height: '100px',  
                    borderRadius: "5px",
                    backgroundColor: "#a1a1a1",
                  }}
                  />
                :
                <Typography
                  sx={{
                    width: "300px",
                    height: '100px',  
                    color: "black",
                    backgroundColor: "white",
                    borderRadius: "5px"
                  }}>
                  {appVersionsBE[3]}
                </Typography>
              }
            </Box>
          </Box>
        </Box>
      </Box>
    
    </Box>
  )
}

export default AppVersionsPage;