import * as actionTypes from "../actionTypes.js";

const initialState = {
  partiesList: null,
  partiesBankAccountsList: null,
};

const businessFusionALTReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_PARTIES_BIZ_FUSION_ALT:
      return { ...state, partiesList: action.payload };
    case actionTypes.GET_PARTIES_BANK_ACCOUNTS_BIZ_FUSION_ALT:
      return { ...state, partiesBankAccountsList: action.payload };


    case actionTypes.LOG_OUT:
      return initialState;

    default:
      return state;
  }
};

export default businessFusionALTReducer;