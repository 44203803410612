import React from 'react';

import { 
  Box, 
  Typography
} from '@mui/material';

import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import FingerprintIcon from '@mui/icons-material/Fingerprint';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import MemoryOutlinedIcon from '@mui/icons-material/MemoryOutlined';
import AttachMoneyOutlinedIcon from '@mui/icons-material/AttachMoneyOutlined';
import CategoryOutlinedIcon from '@mui/icons-material/CategoryOutlined';
import HubIcon from '@mui/icons-material/Hub';
import PhishingIcon from '@mui/icons-material/Phishing';

import Identity from './Identity';
import Notifications from './Notifications';
import Subscriptions from './Subscriptions';
import Core from './Core';
import CoreGlobal from './Core[global]';
import CoreTenantSelect from './Core[tenantSelect]';
import CoreTenant from './Core[tenant]';
import CoreCompanySelect from './Core[companySelect]';
import CoreCompany from './Core[company]';
import Fusion from './Fusion';
import FusionOrum from './Fusion[orum]';
import FusionCurrencyCloudAccountSelect from './Fusion[currencyCloud][accountSelect]';
import FusionCurrencyCloud from './Fusion[currencyCloud]';
import FusionAltcard from './Fusion[altcard]';
import Webhook from './Webhook';

import "../styles.scss";

const BusinessNavigation = ({
  path, 
  currentPage, 
  navigate
}) => {
  return (
    path.length > 2 && 
    path[2] === "identity"
    ?
    // SUBMODULE - identity
    <Identity 
      navigate={navigate}
      currentPage={currentPage}
      />
    :
      path.length > 2 && 
      path[2] === "notifications"
      ?
      // SUBMODULE - notifications
      <Notifications 
        navigate={navigate}
        currentPage={currentPage}
        />
      :
        path.length > 3 && 
        path[2] === "fusion" &&
        path[3] === "orum"
        ?
        // SUBMODULE - fusion > ourm
        <FusionOrum 
          navigate={navigate}
          currentPage={currentPage}
          />
        :
          path.length > 3 && 
          path[2] === "fusion" &&
          path[3] === "altcard"
          ?
          // SUBMODULE - fusion > altcard
          <FusionAltcard
            navigate={navigate}
            currentPage={currentPage}
            />
          :
            path.length === 4 && 
              path[2] === "fusion" &&
              path[3] === "currency-cloud"
              ?
              // SUBMODULE - fusion > currency cloud [account select]
              <FusionCurrencyCloudAccountSelect 
                navigate={navigate}
                currentPage={currentPage}
                />
              :
                path.length > 4 && 
                path[2] === "fusion" &&
                path[3] === "currency-cloud"
                ?
                // SUBMODULE - fusion > currency cloud
                <FusionCurrencyCloud 
                  navigate={navigate}
                  currentPage={currentPage}
                  />
                :
                  path.length > 2 && 
                  path[2] === "fusion"
                  ?
                  // SUBMODULE - fusion
                  <Fusion 
                    navigate={navigate}
                    currentPage={currentPage}
                    />
                  :
                    path.length > 2 && 
                    path[2] === "webhook"
                    ?
                    // SUBMODULE - webhook
                    <Webhook 
                      navigate={navigate}
                      currentPage={currentPage}
                      />
                    :
                      path.length > 2 && 
                      path[2] === "subscriptions"
                      ?
                      // SUBMODULE - subscriptions
                      <Subscriptions 
                        navigate={navigate}
                        currentPage={currentPage}
                        />
                      :
                        path.length === 3 &&
                        path[2] === "core"
                        ?
                        // SUBMODULE - core
                        <Core 
                          navigate={navigate}
                          currentPage={currentPage}
                          />
                        :
                          path.length === 5 &&
                          path[3] === "global"
                          ?
                          // SUBMODULE - core > global
                          <CoreGlobal 
                            navigate={navigate}
                            currentPage={currentPage}
                            />
                          :
                            path.length === 4 &&
                            path[3] === "tenants"
                            ? 
                            // SUBMODULE - core > [tenant selection]
                            <CoreTenantSelect 
                              navigate={navigate}
                              currentPage={currentPage}
                              />
                            :
                              path.length > 4 &&
                              path[3] === "tenants"
                              ?
                              // SUBMODULE - core > tenant
                              <CoreTenant 
                                navigate={navigate}
                                currentPage={currentPage}
                                />
                              :
                                path.length === 4 &&
                                path[3] === "companies"
                                ?
                                // SUBMODULE - core > [company selection]
                                <CoreCompanySelect 
                                  navigate={navigate}
                                  currentPage={currentPage}
                                  />
                                :
                                  path.length > 4 &&
                                  path[3] === "companies"
                                  ?
                                  // SUBMODULE - core > company
                                  <CoreCompany 
                                    navigate={navigate}
                                    currentPage={currentPage}
                                    />
                                  :
                                  // business - home
                                  <Box className="nav__side-common flex_SBColumn" 
                                    display={"flex"} flexDirection={"column"} gap={"10px"}>
                                    <Box className="nav__side-container">
                                      <Box className={`${currentPage === 0 ? 'nav__side-selected' : 'nav__side-item'} flexCenter_Row`}
                                        onClick={() => {
                                          navigate("/business");
                                        }}>
                                        <HomeOutlinedIcon />
                                        <Typography variant='body2'>
                                          Home Page - Business
                                        </Typography>
                                      </Box>
                                      <Box className={`${currentPage === 1 ? 'nav__side-selected' : 'nav__side-item'} flexCenter_Row`}
                                        onClick={() => {
                                          navigate("/business/uploaded-files");
                                        }}>
                                        <UploadFileIcon />
                                        <Typography variant='body2'>
                                          Uploaded files
                                        </Typography>
                                      </Box>
                                      <Box className={`${currentPage === 2 ? 'nav__side-selected' : 'nav__side-item'} flexCenter_Row`}
                                        onClick={() => {
                                          navigate("/business/currencies");
                                        }}>
                                        <AttachMoneyOutlinedIcon />
                                        <Typography variant='body2'>
                                          Currencies
                                        </Typography>
                                      </Box>
                                    </Box>
                                    <Box display={"flex"}
                                      flexDirection={"column"}
                                      gap={"10px"}>
                                      <Box className="nav__side-container">
                                        <Box className={`nav__side-item flexCenter_Row`}
                                          onClick={() => {
                                            navigate("/business/identity");
                                          }}>
                                          <FingerprintIcon />
                                          <Typography variant='body2'>
                                            Identity - MS
                                          </Typography>
                                        </Box>
                                      </Box>
                                      <Box className="nav__side-container">
                                        <Box className={`nav__side-item flexCenter_Row`}
                                          onClick={() => {
                                            navigate("/business/core");
                                          }}>
                                          <MemoryOutlinedIcon />
                                          <Typography variant='body2'>
                                            Core - MS
                                          </Typography>
                                        </Box>
                                      </Box>
                                      <Box className="nav__side-container">
                                        <Box className={`nav__side-item flexCenter_Row`}
                                          onClick={() => {
                                            navigate("/business/fusion");
                                          }}>
                                          <HubIcon />
                                          <Typography variant='body2'>
                                            Fusion - MS
                                          </Typography>
                                        </Box>
                                      </Box>
                                      <Box className="nav__side-container">
                                        <Box className={`nav__side-item flexCenter_Row`}
                                          onClick={() => {
                                            navigate("/business/webhook");
                                          }}>
                                          <PhishingIcon />
                                          <Typography variant='body2'>
                                            Webhook - MS
                                          </Typography>
                                        </Box>
                                      </Box>     
                                      {/* <Box className="nav__side-container">
                                        <Box className={`nav__side-item flexCenter_Row`}
                                          onClick={() => {
                                            navigate("/business/core");
                                          }}>
                                          <CableIcon />
                                          <Typography variant='body2'>
                                            ORUM - MS
                                          </Typography>
                                        </Box>
                                      </Box>                         */}
                                      <Box className="nav__side-container">
                                        <Box className={`nav__side-item flexCenter_Row`}
                                          onClick={() => {
                                            navigate("/business/subscriptions");
                                          }}>
                                          <CategoryOutlinedIcon />
                                          <Typography variant='body2'>
                                            Products & Subscriptions
                                          </Typography>
                                        </Box>
                                      </Box>
                                      <Box className="nav__side-container">
                                        <Box className={`nav__side-item flexCenter_Row`}
                                          onClick={() => {
                                            navigate("/business/notifications/email-templates");
                                          }}>
                                          <NotificationsNoneIcon />
                                          <Typography variant='body2'>
                                            Notifications
                                          </Typography>
                                        </Box>
                                      </Box>
                                      {/* <Box className="nav__side-container">
                                        <Box className={`nav__side-item flexCenter_Row`}
                                          onClick={() => {
                                            navigate("/business/extension");
                                          }}>
                                          <ExtensionOutlinedIcon />
                                          <Typography variant='body2'>
                                            Extension
                                          </Typography>
                                        </Box>
                                      </Box> */}
                                    </Box>
                                  </Box>
  )
}

export default BusinessNavigation